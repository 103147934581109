<template>
  <div></div>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    auth: true,
    layout: 'layout-exterior',
    beforeMount() {
      this.$router.push('/ERF/IL');
    },
  });
</script>
